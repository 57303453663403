import { Box, Flex } from '@rebass/grid';
import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import BlogPreview from '../components/BlogPreview';
import GlobalHead from '../config/GlobalHead';
import GlobalStyle from '../config/GlobalStyle';
import { Provider } from '../contexts/context';
import Footer from '../modules/footer/Footer';
import Header from '../modules/navigation/Header';

const Blog = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter((edge) => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map((edge) => <BlogPreview key={edge.node.id} post={edge.node} />);

  return (
    <Provider>
      <GlobalStyle />
      <GlobalHead />
      <Header />
      <Flex justifyContent="center" p={3}>
        <BlogWrapper>
          <Styledh1>Blog</Styledh1>
          <PostWrapper>{Posts}</PostWrapper>
        </BlogWrapper>
      </Flex>
      <Footer />
    </Provider>
  );
};
export default Blog;

const BlogWrapper = styled(Box)`
  max-width: 1128px;
  width: 100%;
  padding: 50px 0;
`;

const PostWrapper = styled.div`
  margin: 0 auto;
  max-width: 800px;
  width: 100%;
`;

const Styledh1 = styled.h1`
  color: #d26426;
`;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "DD/MM/YYYY")
            title
            image
          }
        }
      }
    }
    allSitePage {
      edges {
        node {
          path
          id
        }
      }
    }
  }
`;
